#locations::-webkit-scrollbar-track
{
    -webkit-box-shadow: var(--box-shadow);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#locations::-webkit-scrollbar
{
    width: 12px;
    background-color: #F5F5F5;
}

#locations::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: var(--box-shadow);
    background-color: #555;
}

select::-ms-expand {
    padding: 20px;
}

select option {
    margin: 40px;
    background:black;
    color: #ffffff
}
  select {
    display: inline-block;
    box-sizing: border-box;
    
    padding: 0.5em 2em 0.5em 0.5em;
    border: 1px solid #eee;
    font: inherit;
    line-height: inherit;
    appearance: none;
    background-repeat: no-repeat;
    background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position: right 15px top 1em, right 10px top 1em;
    background-size: 5px 5px, 5px 5px;
  }