.primary_heading {
    font-size: 3.125vw;
    font-weight: 600;
    letter-spacing: -1.2px;
}
.primary_heading span {
    font-weight: 300;
}

.secondary_heading {
    font-size: 2.91667vw;
    font-weight: 600;
    letter-spacing: -2.24px;
}
.secondary_heading span {
    font-size: 1.875vw;
    font-weight: 300;
    letter-spacing: -1.44px;
}
p {
    font-family: var(--font-family);
    font-weight: 300;
}
.form-group input {
    font-family: var(--font-family);
    font-weight: 300;
}
@media only screen and (max-width: 1199px) {
    .secondary_heading {
        font-size: 28px;
        letter-spacing: -0.56px;
    }
    .secondary_heading span {
        font-size: 18px;
        letter-spacing: -0.36px;
    }
}

@media only screen and (max-width: 767px) {
    .primary_heading {
        letter-spacing: normal;
    }
    .secondary_heading {
        font-size: 28px;
        letter-spacing: -0.56px;
    }
    .secondary_heading span {
        font-size: 18px;
        letter-spacing: -0.36px;
    }
}

/* banner section start css */
.banner_section {
    position: relative;
    overflow: hidden;
}
.banner_section .swiper-slide {
    text-align: left;
}
.banner_content_wrap {
    position: absolute;
    top: 35px;
    left: 3.85417vw;
    right: 3.85417vw;
    max-width: 40.625vw;
}
.banner_content_wrap .primary_heading {
    color: var(--theme-white-color);
    margin-bottom: 0.8em;
}
.banner_btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex !important;
    align-items: center;
    column-gap: 8px;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2.60417vw;
    z-index: 1;
    right: 4.16667vw;
}
.banner_btn li {
    list-style: none;
}
.banner_btn li button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.02083vw;
    height: 3.02083vw;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid var(--theme-white-color);
    background-color: var(--theme-secondary-color);
}
.banner_btn li button svg {
    width: 1.45833vw;
    height: 1.45833vw;
}
.banner_btn li button svg path {
    fill: var(--theme-white-color);
}
.banner_btn li button:hover {
    background-color: var(--theme-primary-color);
}
.banner_section .swiper-pagination-banner {
    display: none;
}
.banner_img_wrap {
    height: 41.66667vw;
}
.banner_img_wrap img {
    height: 100%;
}

@media only screen and (max-width: 1199px) {
    .banner_btn li button {
        width: 30px;
        height: 30px;
        padding: 0;
    }
    .banner_btn li button svg {
        width: 15px;
        height: 15px;
    }
}
@media only screen and (max-width: 767px) {
    .banner_img_wrap {
        height: auto;
    }
    .banner_img_wrap img {
        height: auto;
    }
    .banner_content_wrap {
        max-width: 100%;
        top: 26px;
        left: 14.85417vw;
        right: 14.85417vw;
    }
    .banner_content_wrap .theme-btn {
        display: none;
    }
    .banner_content_wrap .primary_heading span {
        font-size: 14px;
        letter-spacing: -0.56px;
        display: block;
    }
    .banner_content_wrap .primary_heading br {
        display: none;
    }
    .banner_content_wrap .primary_heading {
        font-size: 20px;
        letter-spacing: -0.4px;
        text-align: center;
    }
    .banner_img_wrap {
        width: 100.5%;
        position: relative;
        left: -1px;
    }
    .banner_section .swiper-pagination-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        top: auto;
        bottom: 24px;
        right: 24px;
        left: auto;
        width: auto !important;
    }
    .banner_section .swiper-pagination-banner span {
        background-color: var(--theme-white-70-color);
        width: 7px;
        height: 7px;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
        margin-left: 2px !important;
        margin-right: 2px !important;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .banner_section .swiper-pagination-banner .swiper-pagination-bullet-active {
        width: 20px;
        background-color: var(--theme-primary-color);
    }
}
/* banner section end css */

/* build quality section start css */
.build_quality_section {
    position: relative;
    overflow: hidden;
    padding: 3.54167vw 0;
}
.build_quality_header {
    text-align: center;
}
.build_quality_header .secondary_heading {
    line-height: 1;
    margin-bottom: 0.357em;
}
/* .build_quality_img_wrap {
    margin-left: 4.58333vw;
    margin-right: 4.58333vw;
} */
.build_quality_img_wrap {
    margin-left: 19.58333vw;
    margin-right: 23.95833vw;
    position: relative;
}
.spot_1 {
    /* position: absolute;
    width: 600px;
    bottom: 16%; */
    /* text-align: right; */
    /* display: flex;
    justify-content: space-between;
    right: 65.3%; */
}
.spot_text {
    /* position: relative;
    top: -11.5vw;
    font-size: 27.865px;
    letter-spacing: -0.557px; */
}
.spot_circle {
    /* position: relative;
    width: 24px;
    height: 24px;
    background-color: var(--theme-primary-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; */
}
.spot_circle::before {
    /* content: "";
    position: absolute;
    bottom: 20px;
    width: 1px;
    height: 7.8125vw;
    background-color: var(--theme-primary-color); */
    /* left: 50%; */
    /* background-image: url('data:image/svg+xml,<svg width="604" height="154" viewBox="0 0 604 154" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="603" y1="0.696614" y2="0.696614" stroke="%23686868" stroke-width="1.39323"/><line x1="602.606" y1="153.077" x2="602.606" y2="1.21564" stroke="%23686868" stroke-width="1.39323"/></svg>');
    background-size: contain;
    width: 31.45833vw;
    height: 8.02083vw;
    background-repeat: no-repeat;
    background-color: transparent;
    right: 50%; */
}
.spot_circle::after {
    /* content: "";
    position: absolute;
    height: 1px;
    width: 31.40625vw;
    background-color: var(--theme-primary-color);
    bottom: 7.8125vw;
    right: 0; */
}
.spot_1 {
    position: absolute;
    bottom: 24%;
    right: 65%;
    width: 32.03125vw;
}
.spot_text {
    font-size: 1.40625vw;
    font-weight: 300;
}
.spot_circle svg {
    width: 100%;
    height: 100%;
    overflow: visible;
}
.spot_2 {
    position: absolute;
    bottom: 1%;
    right: 43.5%;
    width: 42.5vw;
}
.spot_2 .spot_text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    width: 100%;
    align-items: flex-end;
    height: 100%;
    bottom: 10px;
}
.spot_3 {
    position: absolute;
    bottom: 9%;
    right: -42%;
    width: 39.27083vw;
}
.spot_3 .spot_text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    align-items: flex-end;
    height: 100%;
    bottom: 10px;
}
.spot_4 {
    position: absolute;
    top: 46%;
    right: -15%;
    width: 21.875vw;
}
.spot_4 .spot_text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.spot_5 {
    position: absolute;
    top: 20.5%;
    right: -37%;
    width: 31.875vw;
}
.spot_5 .spot_text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.spot svg .circle_pulse {
    /* fill: rgba(#fff, .5);
	animation: pulse2 1.5s ease-in-out infinite; */
}
/* @keyframes pulse2 {
	0% {
		transform: scale(1, 1);
		opacity: 0;
	}

	50% {
	    opacity: 1;
	}

	100% {
	    transform: scale(6, 6);
	    opacity: 0;
		}
} */
@media only screen and (max-width: 767px) {
    .build_quality_img_wrap img {
        max-width: 105%;
        display: inline-block;
    }
}
@media only screen and (max-width: 767px) {
    .build_quality_section {
        padding: 24px 0;
    }
    .build_quality_img_wrap {
        margin-left: 0;
        margin-right: 0;
        margin-top: 18.90625vw;
        margin-bottom: 10.60417vw;
    }
    .build_quality_img_wrap img {
        max-width: 105%;
        display: inline-block;
    }
    .spot_text {
        font-size: 12px;
        /* font-weight: 400; */
        max-width: 130px;
    }
    .spot_1 .spot_text {
        /* bottom: 20px;
        position: relative; */
    }
    .spot_circle svg circle {
        /* r: 40px; */
    }
    .spot_1 {
        width: 28.20833vw;
        bottom: -10%;
        right: auto;
        left: 7%;
    }
    .spot_1 .spot_text {
        position: absolute;
        bottom: 5%;
        left: -18%;
    }
    .spot_2 {
        position: absolute;
        bottom: 25%;
        right: 41.5%;
        width: 54.05208vw;
    }
    .spot_2 .spot_text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        align-items: flex-end;
        height: 100%;
        bottom: 115%;
        width: 110px;
        left: 0%;
    }
    .spot_3 {
        position: absolute;
        bottom: -13%;
        right: 12%;
        width: 24.6875vw;
    }
    .spot_3 .spot_text {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        width: 100%;
        align-items: flex-end;
        height: 100%;
        bottom: 10px;
        right: -25%;
    }
    .spot_4 {
        position: absolute;
        top: -12%;
        right: 26%;
        width: 55.67708vw;
    }
    .spot_4 .spot_text {
        justify-content: flex-start;
        position: relative;
        top: -5px;
    }
    .spot_5 {
        position: absolute;
        top: -18.5%;
        right: 0%;
        width: 25.875vw;
    }
    .spot_5 .spot_text {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        position: relative;
        right: -12%;
    }
}
/* build quality section end css */

/* technology section start css */
.technology_section {
    position: relative;
    overflow: hidden;
    padding: 3.54167vw 0 3.125vw;
}
.tech_img_wrap {
    margin-left: 4.27083vw;
}
.tech_img_wrap .tech_img_lg {
    position: relative;
    margin-bottom: 4.42708vw;
}
.tech_img_wrap .tech_img_lg > img {
    max-width: 40.625vw;
}
.tech_img_wrap .tech_img_sm {
    position: absolute;
    bottom: -4.42708vw;
    right: 0;
    width: 19.53125vw;
}
.tech_img_wrap .tech_img_sm > img {
    /* max-width: 19.53125vw; */
}
.tech_content_wrap {
    max-width: 584px;
    margin-left: 4.16667vw;
}
.tech_header {
    max-width: 300px;
    max-width: none;
}
.tech_header .secondary_heading {
    color: var(--theme-white-color);
    line-height: 1.4;
    text-transform: capitalize;
}
.tech_header .secondary_heading span {
    /* display: block; */
}
.tech_list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    margin: 2.08333vw 0;
    padding: 0;
    list-style: none;
    row-gap: 20px;
}
.tech_list li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    column-gap: 16px;
    flex: 0 0 auto;
    width: 100%;
    color: var(--theme-white-color);
    font-size: 18px;
    font-weight: 300;
}
.tech_list li .tech_icon {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    padding: 12px;
    background-color: var(--theme-primary-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.tech_content,
.tech_content p {
    color: var(--theme-white-color);
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 0;
}

@media only screen and (max-width: 1499px) {
    .tech_list li {
        font-size: 16px;
    }
    .tech_content,
    .tech_content p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .technology_section {
        padding: 24px 0;
    }
    .technology_section .row {
        flex-direction: column-reverse;
    }
    .tech_header {
        /* max-width: 220px; */
    }
    .tech_img_wrap {
        margin-left: 0;
        margin-top: 24px;
    }
    .tech_content_wrap {
        margin-left: 0;
    }
    .tech_img_wrap .tech_img_lg > img {
        max-width: 70%;
    }
    .tech_img_wrap .tech_img_sm {
        width: 47%;
        right: 10px;
        bottom: auto;
        top: 64%;
    }
    .tech_img_wrap .tech_img_lg {
        position: relative;
        margin-bottom: 15.42708vw;
    }
    .tech_list {
        flex-wrap: nowrap;
        column-gap: 20px;
        row-gap: 0;
        margin: 24px 0;
    }
    .tech_list li {
        width: calc((100% - 20px) / 2);
        flex-wrap: wrap;
        font-size: 14px;
    }
    .tech_list li .tech_icon {
        width: 52px;
        height: 52px;
        padding: 12px;
        margin-bottom: 8px;
    }
}
/* technology section end css */

/* performance section start css */
.performance_section {
    position: relative;
    overflow: hidden;
    padding: 3.54167vw 0;
}
.performance_header .secondary_heading {
    line-height: 1;
    margin-bottom: 0.714em;
}
.performance_list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
}
.performance_list li a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    column-gap: 12px;
    align-items: center;
    padding: 1.25vw 1.5625vw 1.04167vw;
    position: relative;
    font-size: 1.25vw;
    letter-spacing: -0.72px;
    color: rgba(28, 28, 28, 0.45);
}
.performance_list li a.active {
    color: var(--theme-secondary-color);
}
.performance_list li a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: transparent;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    transition: background-color 0.3s ease-in-out;
    -webkit-transition: background-color 0.3s ease-in-out;
    -moz-transition: background-color 0.3s ease-in-out;
    -ms-transition: background-color 0.3s ease-in-out;
    -o-transition: background-color 0.3s ease-in-out;
}
.performance_list li a.active::before {
    background-color: var(--theme-primary-color);
}
.performance_list li a.active svg path {
    fill: var(--theme-primary-color);
    fill-opacity: 1;
}
.performance_list li a .list_icon {
    width: 2.1875vw;
    height: 2.1875vw;
}
.performance_list li a .list_icon svg {
    width: 100%;
    height: 100%;
}
.performance_tab_wrap {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    align-items: flex-end;
}
.performance_tab {
    display: none;
}
.performance_tab.tab_active {
    display: block;
}
.performance_content_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* align-items: flex-end; */
    position: relative;
    background-color: var(--theme-light-gray-color);
}
.performance_content_wrap .shape_1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 36.71875vw;
    height: 4.94792vw;
    background-color: var(--theme-white-color);
}
.performance_content_wrap .shape_2 {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 20.05208vw;
    height: 3.125vw;
    background-color: var(--theme-white-color);
}
.performance_content {
    margin-top: 4.94792vw;
    padding: 1.66667vw 2.08333vw;
    max-width: 32.29167vw;
    background-color: var(--theme-light-gray-color);
}
.performance_content .heading {
    font-size: 1.875vw;
    font-weight: 600;
    letter-spacing: -1.44px;
    margin-bottom: 8px;
}
.performance_content p {
    font-size: 18px;
    font-weight: 300;
    font-family: var(--font-family);
}
.performance_content p {
    margin-bottom: 0;
}
.performance_content p + p {
    margin-top: 16px;
}
.performance_img_wrap {
    max-width: 37.08333vw;
    position: relative;
    position: relative;
    z-index: 1;
}
.performance_content_wrap::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    height: 91%;
    z-index: 0;
    background-color: var(--theme-primary-color);
}

@media only screen and (max-width: 1499px) {
    .performance_content p {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1199px) {
    .performance_list li a {
        letter-spacing: normal;
    }
    .performance_list li a .list_icon {
        width: 27px;
        height: 27px;
    }
    .performance_list li a .list_icon svg {
        width: 100%;
        height: 100%;
    }
    .performance_content .heading {
        letter-spacing: -0.74px;
    }
}
@media only screen and (max-width: 767px) {
    .performance_section {
        padding: 24px 0 0;
    }
    .performance_header {
        max-width: 200px;
    }
    .performance_list {
        flex-direction: row;
        white-space: nowrap;
        /* column-gap: 16px; */
        margin-right: -16px;
        overflow-x: auto;
        padding-right: 10px;
        position: relative;
        /* bottom: -17px; */
    }
    .performance_list::after {
        /* content: "";
        width: 100%;
        height: 2px;
        background-color: rgba(28, 28, 28, 0.10);
        bottom: 0;
        position: absolute;
        z-index: -1; */
    }
    .performance_list::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        height: 0px;
    }
    .performance_list::-webkit-scrollbar-track {
        width: 0;
        height: 0;
    }
    .performance_list li a {
        font-size: 14px;
        column-gap: 6px;
        padding: 4px 3px 15px 4px;
    }
    .performance_list li {
        position: relative;
    }
    .performance_list li + li {
        padding-left: 16px;
    }
    .performance_list li a::before {
        top: auto;
        bottom: 0;
        width: 100%;
        height: 2px;
    }
    .performance_list li::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: rgba(28, 28, 28, 0.1);
    }
    .performance_list li a .list_icon {
        width: 27px;
        height: 27px;
    }
    .performance_list li a .list_icon svg {
        width: 100%;
        height: 100%;
    }
    .performance_content .heading {
        font-size: 24px;
        letter-spacing: -0.96px;
    }
    .performance_content_wrap {
        flex-wrap: wrap;
        margin-left: -16px;
        margin-right: -16px;
        padding-bottom: 24px;
    }
    .performance_content {
        max-width: 100%;
        padding: 16px;
    }
    .performance_img_wrap {
        max-width: 100%;
    }
    .performance_img_wrap::after {
        display: none;
    }
    .performance_content_wrap .shape_1,
    .performance_content_wrap .shape_2 {
        display: none;
    }
    .performance_content_wrap::after {
        display: none;
    }
}
/* performance section end css */

/* feature section start css */
.feature_section {
    position: relative;
    overflow: hidden;
    padding: 4.16667vw 0 3.64583vw;
}
.feature_wrap {
    position: relative;
}
.feature_text_wrap {
    position: absolute;
    max-width: 85.9375vw;
    left: 0;
    right: 0;
    margin: auto;
    top: -5.20833vw;
    z-index: 0;
}
.feature_auto_wrap {
    max-width: 40.36458vw;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.feature_auto_wrap::before {
    content: "";
    width: 31.25vw;
    height: 31.25vw;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-filter: blur(154.56301879882812px);
    filter: blur(154.56301879882812px);
    position: absolute;
    z-index: 0;
    top: 10%;
    left: 0;
}
.feature_auto_wrap img {
    position: relative;
    z-index: 1;
}
.feature_list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    column-gap: 6.25vw;
    margin-top: -3%;
}
.feature_list li {
    width: calc((100% - 6.25vw) / 6);
}
.fearure_list_img_wrap {
    margin-bottom: 16px;
}
.feature_content .top_content {
    font-size: 0.9375vw;
    font-weight: 300;
    color: var(--theme-white-color);
    text-transform: uppercase;
}
.feature_content .bottom_content {
    font-size: 1.875vw;
    font-size: 1.275vw;
    font-weight: 600;
    color: var(--theme-primary-color);
    letter-spacing: -1.44px;
    line-height: 1.4;
}

@media only screen and (max-width: 1499px) {
    .feature_list {
        justify-content: space-between;
        column-gap: 0;
    }
}
@media only screen and (max-width: 1199px) {
    .feature_content .top_content {
        font-size: 13px;
        margin-bottom: 8px;
    }
    .feature_content .bottom_content {
        font-size: 14px;
        letter-spacing: normal;
    }
}

@media only screen and (max-width: 767px) {
    .feature_text_wrap {
        max-width: 100%;
        top: 0;
    }
    .feature_auto_wrap {
        max-width: 98%;
        top: 20px;
    }
    .feature_list_wrap {
        margin-top: 24px;
    }
    .feature_list {
        flex-wrap: wrap;
    }
    .feature_auto_wrap::before {
        width: 60%;
        height: 60%;
        right: 0;
        left: 0;
        margin: auto;
        -webkit-filter: blur(94.563019px);
        filter: blur(94.563019px);
        top: 22%;
    }
    .feature_list li {
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        column-gap: 16px;
        padding-bottom: 9px;
        padding-top: 9px;
    }
    .feature_list li + li {
        border-top: 1px solid var(--theme-white-20-color);
    }
    .fearure_list_img_wrap {
        width: 33px;
        height: 33px;
        flex: 0 0 auto;
    }
    .feature_content .top_content {
        font-size: 14px;
    }
    .feature_content .bottom_content {
        font-size: 24px;
    }
}
/* feature section end css */

/* powerful section start css */
.powerful_section {
    position: relative;
    overflow: hidden;
    padding: 3.54167vw 0;
    border-top: 1px solid var(--theme-white-20-color);
}
.powerful_header {
    max-width: 59.0625vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.powerful_header .secondary_heading {
    color: var(--theme-white-color);
    margin-bottom: 0.357em;
    text-transform: capitalize;
}
.powerful_header p {
    font-size: 18px;
    color: var(--theme-white-color);
    margin-bottom: 0;
}
.powerful_btn_wrap {
    margin-top: 35px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

@media only screen and (max-width: 1499px) {
    .powerful_header p {
        font-size: 14px;
    }
}
@media only screen and (max-width: 767px) {
    .powerful_section {
        padding: 24px 0;
    }
    .powerful_header {
        max-width: 100%;
        text-align: left;
    }
    .powerful_header .secondary_heading {
        margin-bottom: 20px;
    }
    .powerful_header p {
        letter-spacing: -0.28px;
    }
    .powerful_btn_wrap {
        flex-wrap: wrap;
    }
    .powerful_btn_wrap .theme-btn {
        width: 100%;
    }
    .powerful_btn_wrap .theme-btn + .theme-btn {
        margin-top: 10px;
    }
}
/* powerful section end css */

/* compare section start css */
.compare_section {
    position: relative;
    overflow: hidden;
    padding: 3.54167vw 0;
}
.compare_header {
    text-align: center;
}
.compare_header .secondary_heading {
    color: var(--theme-white-color);
    margin-bottom: 0.357em;
    position: relative;
    z-index: 1;
}
.compare_header .secondary_heading span {
    display: inline-block;
    position: relative;
    top: -0.625vw;
}
.compare_body {
    position: relative;
    padding-left: 4.16667vw;
    padding-right: 4.16667vw;
}
.compary_body_img_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    column-gap: 16px;
    margin-top: -7%;
}
.compary_body_img_wrap ._left,
.compary_body_img_wrap ._right {
    position: relative;
    height: 100%;
}
.compary_body_img_wrap ._left .sr_heading,
.compary_body_img_wrap ._right .sr_heading {
    position: absolute;
    bottom: 1.30208vw;
    left: 1.66667vw;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.406px;
    color: var(--theme-white-color);
}
.compare_accordion .compare_accordion_item {
    background-color: var(--theme-white-color);
}
.compare_accordion .compare_accordion_item + .compare_accordion_item {
    border-top: 1px solid rgba(28, 28, 28, 0.1);
}
.compare_accordion .compare_accordion_item .accordion_title {
    color: var(--theme-secondary-color);
    padding: 22px 24px;
    cursor: pointer;
    position: relative;
}
.compare_accordion .compare_accordion_item .accordion_title::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
}
.compare_accordion .compare_accordion_item .accordion_title .title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: var(--theme-secondary-color);
    cursor: pointer;
    display: block;
    position: relative;
    transition: color 0.3s ease-in-out;
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
}
.compare_accordion .compare_accordion_item .title .compare_icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.compare_accordion .compare_accordion_item .accordion_content {
    padding: 24px;
    background-color: var(--theme-light-gray-color);
}
.compare_accordion
    .compare_accordion_item
    .accordion_content
    .accordion_content_item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}
.compare_accordion .compare_accordion_item .accordion_content ._left,
.compare_accordion .compare_accordion_item .accordion_content ._right {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    max-width: 37.70833vw;
    padding: 0;
}
.compare_accordion .compare_accordion_item .accordion_content .seperator {
    width: 1px;
    height: 5.46875vw;
    background-color: rgba(28, 28, 28, 0.45);
    margin-left: 3.125vw;
    margin-right: 3.125vw;
}
.compare_accordion .compare_accordion_item .accordion_content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.compare_accordion .compare_accordion_item .accordion_content p,
.compare_accordion .compare_accordion_item .accordion_content ul {
    max-width: 90%;
    color: var(--theme-secondary-color);
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    transition: color 0.3s ease-in-out;
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
}
.compare_accordion .compare_accordion_item .accordion_content ul li {
    font-size: 16px;
    letter-spacing: -0.32px;
    position: relative;
    padding-left: 24px;
}
.compare_accordion .compare_accordion_item .accordion_content ul li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><circle cx="6" cy="6.58496" r="5" stroke="%23356998" stroke-width="2"/></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
.compare_accordion .compare_accordion_item .accordion_content ul li + li {
    margin-top: 20px;
}
.compare_accordion
    .compare_accordion_item
    .accordion_content
    ul
    li
    span:first-child {
    font-weight: 600;
    min-width: 270px;
    position: relative;
}
.compare_accordion
    .compare_accordion_item
    .accordion_content
    ul
    li
    span:first-child::after {
    content: ":";
    position: absolute;
    right: 0;
    top: 0;
}
.compare_accordion .compare_accordion_item.active {
    background-color: var(--theme-secondary-color);
}
.compare_accordion .compare_accordion_item.active .title {
    color: var(--theme-white-color);
}
.compare_accordion .compare_accordion_item.active .accordion_title::before {
    background-color: var(--theme-primary-color);
    background-color: #69a7de;
}
.compare_accordion .compare_accordion_item.active .accordion_content {
    background-color: var(--theme-light-gray-color);
}
.compare_accordion .compare_accordion_item.active .title .compare_icon {
    transform: translateY(-50%) rotate(0deg);
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
}
.compare_accordion
    .compare_accordion_item.active
    .title
    .compare_icon
    svg
    rect {
    fill: var(--theme-primary-color);
}
.compare_accordion
    .compare_accordion_item.active
    .title
    .compare_icon
    svg
    path {
    stroke: var(--theme-white-color);
}
.compare_accordion
    .compare_accordion_item:not(:first-child)
    .accordion_content {
    display: none;
}

@media only screen and (max-width: 1499px) {
    .compare_accordion .compare_accordion_item .accordion_content ul li {
        font-size: 14px;
    }
    .compare_accordion .compare_accordion_item .accordion_content ul li + li {
        margin-top: 12px;
    }
}
@media only screen and (max-width: 1199px) {
    .compare_accordion .compare_accordion_item .accordion_content {
        padding: 20px;
    }
    .compare_accordion .compare_accordion_item .accordion_title {
        padding: 16px 20px;
    }
    .compare_accordion .compare_accordion_item .accordion_title .title {
        font-size: 16px;
    }
}
@media only screen and (max-width: 767px) {
    .compare_section {
        padding: 24px 0 0;
    }
    .compare_body {
        padding-left: 0;
        padding-right: 0;
        margin-left: -16px;
        margin-right: -16px;
    }
    .compary_body_img_wrap {
        margin-left: 16px;
        margin-right: 16px;
        column-gap: 4px;
    }
    .compary_body_img_wrap ._left .sr_heading,
    .compary_body_img_wrap ._right .sr_heading {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: -0.24px;
    }
    .compare_accordion .compare_accordion_item .accordion_title {
        padding: 12px 16px;
    }
    .compare_accordion .compare_accordion_item .accordion_title .title {
        font-size: 16px;
    }
    .compare_accordion .compare_accordion_item .title .compare_icon {
        width: 28px;
        height: 28px;
    }
    .compare_accordion .compare_accordion_item .title .compare_icon svg {
        width: 100%;
        height: 100%;
    }
    .compare_accordion .compare_accordion_item .accordion_content {
        padding: 16px;
    }
    .compare_accordion
        .compare_accordion_item
        .accordion_content
        .accordion_content_item {
        height: 100%;
    }
    .compare_accordion .compare_accordion_item .accordion_content ._left,
    .compare_accordion .compare_accordion_item .accordion_content ._right {
        max-width: 100%;
    }
    .compare_accordion .compare_accordion_item .accordion_content .seperator {
        margin-left: 16px;
        margin-right: 16px;
        height: auto;
    }
    .compare_accordion .compare_accordion_item .accordion_content ul li + li {
        margin-top: 16px;
    }
    .compare_accordion .compare_accordion_item .accordion_content p,
    .compare_accordion .compare_accordion_item .accordion_content ul {
        max-width: 100%;
    }
    .compare_accordion
        .compare_accordion_item
        .accordion_content
        ul
        li::before {
        top: 5px;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }
}
/* compare section end css */

/* other specifiction section start css */
.other_spec_section {
    position: relative;
    overflow: hidden;
    padding: 2.08333vw 0;
}
.other_spec_wrap {
    position: relative;
}
.other_spec_toggle {
    position: absolute;
    top: 28px;
    left: 28px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    z-index: 1;
}
.other_spec_toggle .theme-btn {
    padding: 8px 32px;
    background-color: var(--theme-white-20-color);
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border: none;
    font-size: 1.04167vw;
    color: var(--theme-white-60-color);
    border: 1px solid transparent;
}
.other_spec_toggle .theme-btn.active {
    background-color: var(--theme-white-color);
    color: var(--theme-primary-color);
    font-weight: 600;
}
.other_spec_left {
    background-color: var(--theme-primary-color);
    height: 100%;
    min-height: 45.83333vw;
    position: relative;
}
.other_spec_header {
    max-width: 250px;
    padding-left: 1.45833vw;
    padding-top: 6.25vw;
    padding-bottom: 7.8125vw;
}
.other_spec_header .secondary_heading {
    color: var(--theme-white-color);
    line-height: 1;
}
.other_spec_img_wrap {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: -10%;
}
.other_spec_img_wrap .img-fluid {
    display: none;
    max-width: 120%;
}
.other_spec_img_wrap .img-fluid.active {
    display: inline-block;
}
.other_spec_tab {
    display: none;
}
.other_spec_tab.tab_active {
    display: block;
}
.spec_accordion {
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
    -o-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
    -ms-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
}
.spec_accordion .spec_accordion_item {
    background-color: var(--theme-white-color);
}
.spec_accordion .spec_accordion_item + .spec_accordion_item {
    border-top: 1px solid rgba(28, 28, 28, 0.1);
}
.spec_accordion .spec_accordion_item .accordion_title {
    color: var(--theme-secondary-color);
    padding: 22px 24px;
    cursor: pointer;
    position: relative;
}
.spec_accordion .spec_accordion_item .accordion_title::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
}
.spec_accordion .spec_accordion_item .accordion_title .title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: var(--theme-secondary-color);
    cursor: pointer;
    display: block;
    position: relative;
    transition: color 0.3s ease-in-out;
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
}
.spec_accordion .spec_accordion_item .title .spec_icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.spec_accordion .spec_accordion_item .accordion_content {
    padding: 24px;
    background-color: var(--theme-light-gray-color);
}
.spec_accordion .spec_accordion_item .accordion_content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.spec_accordion .spec_accordion_item .accordion_content p,
.spec_accordion .spec_accordion_item .accordion_content ul {
    max-width: 90%;
    color: var(--theme-secondary-color);
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    transition: color 0.3s ease-in-out;
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
}
.spec_accordion .spec_accordion_item .accordion_content ul li {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    column-gap: 10px;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.32px;
}
.spec_accordion .spec_accordion_item .accordion_content ul li + li {
    margin-top: 20px;
}
.spec_accordion .spec_accordion_item .accordion_content ul li span:first-child {
    font-weight: 600;
    min-width: 14.0625vw;
    position: relative;
}
.spec_accordion
    .spec_accordion_item
    .accordion_content
    ul
    li
    span:first-child::after {
    content: ":";
    position: absolute;
    right: 0;
    top: 0;
}
.spec_accordion .spec_accordion_item.active {
    background-color: var(--theme-secondary-color);
}
.spec_accordion .spec_accordion_item.active .title {
    color: var(--theme-white-color);
}
.spec_accordion .spec_accordion_item.active .accordion_title::before {
    background-color: var(--theme-primary-color);
    background-color: #69a7de;
}
.spec_accordion .spec_accordion_item.active .accordion_content {
    background-color: var(--theme-light-gray-color);
}
.spec_accordion .spec_accordion_item.active .title .spec_icon {
    transform: translateY(-50%) rotate(0deg);
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
}
.spec_accordion .spec_accordion_item.active .title .spec_icon svg rect {
    fill: var(--theme-primary-color);
}
.spec_accordion .spec_accordion_item.active .title .spec_icon svg path {
    stroke: var(--theme-white-color);
}
.spec_accordion .spec_accordion_item:not(:first-child) .accordion_content {
    display: none;
}

@media only screen and (max-width: 1499px) {
    .other_spec_toggle .theme-btn {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

@media only screen and (max-width: 1199px) {
    .spec_accordion .spec_accordion_item .accordion_title {
        padding: 16px 20px;
    }
    .spec_accordion .spec_accordion_item .accordion_title .title {
        font-size: 16px;
    }
    .spec_accordion .spec_accordion_item .accordion_content {
        padding: 20px;
    }
    .other_spec_toggle .theme-btn {
        font-size: 14px;
    }
    .other_spec_header {
        max-width: 200px;
        padding-top: 100px;
    }
    .spec_accordion .spec_accordion_item .title .spec_icon {
        width: 28px;
        height: 28px;
    }
    .spec_accordion .spec_accordion_item .title .spec_icon svg {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .other_spec_toggle .theme-btn {
        padding: 4px 20px;
    }
    .spec_accordion .spec_accordion_item .accordion_content ul li + li {
        margin-top: 10px;
    }
    .spec_accordion
        .spec_accordion_item
        .accordion_content
        ul
        li
        span:first-child {
        min-width: 200px;
    }
}

@media only screen and (max-width: 767px) {
    .other_spec_section {
        padding: 0;
    }
    .other_spec_img_wrap {
        position: relative;
        left: 0;
        right: 0;
    }
    .other_spec_img_wrap .img-fluid {
        max-width: 100%;
    }
    .other_spec_toggle {
        top: 24px;
        right: 16px;
        left: auto;
    }
    .other_spec_wrap {
        margin-left: -16px;
        margin-right: -16px;
    }
    .other_spec_header {
        max-width: 200px;
        padding-left: 16px;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .other_spec_toggle .theme-btn {
        font-size: 16px;
        padding: 4px 24px;
        font-weight: 600;
        letter-spacing: normal;
    }
    .spec_accordion .spec_accordion_item .accordion_title .title {
        font-size: 16px;
    }
    .spec_accordion .spec_accordion_item .title .spec_icon {
        width: 28px;
        height: 28px;
    }
    .spec_accordion .spec_accordion_item .title .spec_icon svg {
        width: 100%;
        height: 100%;
    }
    .spec_accordion .spec_accordion_item .accordion_content {
        padding: 16px;
    }
    .spec_accordion .spec_accordion_item .accordion_title {
        padding: 12px 16px;
    }
    .spec_accordion
        .spec_accordion_item
        .accordion_content
        ul
        li
        span:first-child {
        min-width: 200px;
    }
    .spec_accordion .spec_accordion_item .accordion_content ul li + li {
        margin-top: 20px;
    }
}
/* other specifiction section end css */

/* quality section start css */
.quality_section {
    position: relative;
    overflow: hidden;
}
.quality_img_wrap {
    max-width: 26.45833vw;
    margin-left: -3.85417vw;
}
.quality_list_wrap {
    position: relative;
    padding: 5.20833vw 0;
    height: 100%;
}
.quality_lg_txt {
    position: absolute;
    width: 100%;
    font-size: 15.10417vw;
    font-size: 14.58333vw;
    font-weight: 600;
    letter-spacing: -5.817px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.03);
    left: 55px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    pointer-events: none;
}
.quality_list {
    position: relative;
    padding: 0;
    list-style: none;
    max-width: 51.30208vw;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 17.96875vw;
    margin-bottom: 0px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}
.quality_list::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2.286px;
    border-bottom: 2px dashed var(--theme-white-color);
    width: 100%;
}
.quality_list li {
    position: relative;
    width: calc(100% / 4);
}
.quality_list li::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    width: 1px;
    height: 11.63542vw;
    background-color: rgba(255, 255, 255, 0.2);
}
.quality_list li::after {
    content: "";
    position: absolute;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none"><circle cx="11.4536" cy="10.9782" r="10.8606" fill="white"/><circle cx="11.4535" cy="10.9781" r="6.28769" fill="%231C1C1C"/></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.19792vw;
    height: 1.14583vw;
    bottom: -0.52083vw;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    animation: pulse 2s infinite;
    -webkit-animation: pulse 2s infinite;
    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    -ms-border-radius: 200px;
    -o-border-radius: 200px;
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        box-shadow: 0 0 0 1.04167vw rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    }
}

@-moz-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        box-shadow: 0 0 0 1.04167vw rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    }
}

@-o-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        box-shadow: 0 0 0 1.04167vw rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    }
}

@-ms-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        box-shadow: 0 0 0 1.04167vw rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        box-shadow: 0 0 0 1.04167vw rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    }
}
.quality_list_img_wrap {
    width: 8.07292vw;
    height: 8.07292vw;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: var(--theme-secondary-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    top: -17.63542vw;
}
.quality_content_wrap {
    text-align: center;
    position: absolute;
    margin-top: 1.25vw;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
.quality_content_wrap .top_content {
    font-size: 1.875vw;
    font-weight: 600;
    letter-spacing: -1.44px;
    color: var(--theme-primary-color);
}
.quality_content_wrap .bottom_content {
    font-size: 0.9375vw;
    text-transform: uppercase;
    color: var(--theme-white-color);
}

@media only screen and (max-width: 1199px) {
    .quality_list_img_wrap {
        padding: 10px;
    }
    .quality_content_wrap .top_content {
        letter-spacing: -0.74px;
    }
    .quality_content_wrap .bottom_content {
        font-size: 12px;
    }
}
@media only screen and (max-width: 767px) {
    .quality_section {
        padding: 24px 0;
        background: rgb(245, 245, 245);
        background: -moz-linear-gradient(
            180deg,
            rgba(245, 245, 245, 1) 50%,
            rgba(28, 28, 28, 1) 50%
        );
        background: -webkit-linear-gradient(
            180deg,
            rgba(245, 245, 245, 1) 50%,
            rgba(28, 28, 28, 1) 50%
        );
        background: linear-gradient(
            180deg,
            rgba(245, 245, 245, 1) 50%,
            rgba(28, 28, 28, 1) 50%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5",endColorstr="#1c1c1c",GradientType=1);
    }
    .quality_img_wrap {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .quality_list_wrap {
        padding: 24px 0 0;
        padding-left: 15px;
        margin-right: -16px;
    }
    .quality_list {
        max-width: 100%;
        margin-top: 0px;
        margin-bottom: 30px;
        max-width: unset;
        width: unset;
    }
    .quality_list::before {
        display: none;
    }
    .quality_list li {
        width: 100%;
    }
    .quality_list li::before {
        display: none;
    }
    .quality_list li::after {
        display: none;
    }
    .quality_list li::before {
        content: "";
        display: block;
        position: absolute;
        left: -42px;
        left: -35px;
        top: 35px;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="2" height="76" viewBox="0 0 2 76" fill="none"><path d="M1 74.9458L1 0.703756" stroke="url(%23paint0_linear_64_1824)" stroke-width="1.00318" stroke-linecap="round"/><defs><linearGradient id="paint0_linear_64_1824" x1="3" y1="74.9458" x2="3" y2="0.703758" gradientUnits="userSpaceOnUse"><stop stop-color="white" stop-opacity="0"/><stop offset="0.535" stop-color="white"/><stop offset="1" stop-color="white" stop-opacity="0"/></linearGradient></defs></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        width: 2px;
        height: 76px;
    }
    .quality_list_img_wrap {
        position: relative;
        width: 50px;
        height: 50px;
        top: 0;
        padding: 5px;
        left: unset;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }
    .quality_content_wrap {
        position: relative;
        margin-top: 12px;
        text-align: left;
        left: unset;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }
    .quality_content_wrap .top_content {
        font-size: 24px;
        margin-bottom: 4px;
        letter-spacing: -0.96px;
    }
    .quality_content_wrap .bottom_content {
        font-size: 14px;
        max-width: 140px;
    }
    .quality_section .swiper-pagination-quality {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 0px;
        margin-top: 27px;
    }
    .quality_section .swiper-pagination-quality span {
        background-color: var(--theme-white-70-color);
        width: 7px;
        height: 7px;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
        margin-left: 2px !important;
        margin-right: 2px !important;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .quality_section
        .swiper-pagination-quality
        .swiper-pagination-bullet-active {
        width: 20px;
        background-color: var(--theme-primary-color);
    }
    .quality_lg_txt {
        display: none;
    }
}
/* quality section end css */

/* brand video section start css */

.brand_video_section {
    position: relative;
    overflow: hidden;
}
.brand_video_section .video_wrap {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: -2px;
}
.brand_video_section .video_wrap .video_item {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* brand video section end css */

/* assistance section start css */
.assistance_section {
    position: relative;
    overflow: hidden;
    padding: 2.8125vw 0 0;
}
.assistance_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.assistance_wrap .seperator {
    width: 1px;
    height: 9.16667vw;
    background-color: var(--theme-white-70-color);
}
.assistance_col {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    column-gap: 1.25vw;
    flex: 0 0 auto;
    width: auto;
}
.assistance_col .img_wrap {
    flex: 0 0 auto;
    width: 7.55208vw;
}
.assistance_col .assistance_content {
    flex: 0 0 50%;
    width: auto;
}
.assistance_col._left {
    max-width: 25.36458vw;
    margin-right: 5.72917vw;
}
.assistance_col._right {
    margin-left: 5.72917vw;
}
.assistance_col._right .assistance_content {
    flex: 0 0 auto;
}
.assistance_content .top_content {
    font-size: 1.875vw;
    font-weight: 600;
    letter-spacing: -1.44px;
    line-height: 1.4;
    color: var(--theme-white-color);
}
.assistance_content .middle_content {
    font-size: 3.125vw;
    font-weight: 600;
    letter-spacing: -1.2px;
    line-height: 1.2;
    color: var(--theme-white-color);
    margin-bottom: 8px;
}
.assistance_content .bottom_content {
    font-size: 1.04167vw;
    font-weight: 300;
    letter-spacing: -0.6px;
    line-height: 1.3;
    color: var(--theme-white-color);
}
.assistance_vehicle {
    position: relative;
}
.assistance_vehicle .img_wrap {
    max-width: 17.44792vw;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.assistance_vehicle .img_wrap::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.4);
    width: 21.97917vw;
    height: 21.97917vw;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-filter: blur(120px);
    filter: blur(120px);
    z-index: 0;
}
.assistance_vehicle .img_wrap img {
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 1499px) {
    .assistance_content .bottom_content {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1199px) {
    .assistance_content .top_content {
        letter-spacing: -0.74px;
    }
}
@media only screen and (max-width: 991px) {
    .assistance_col .assistance_content {
        flex: 0 0 85%;
        width: auto;
    }
    .assistance_col._right {
        margin-left: 3.72917vw;
    }
}
@media only screen and (max-width: 767px) {
    .assistance_section {
        padding: 24px 0 0;
    }
    .assistance_vehicle .img_wrap {
        max-width: 80%;
    }
    .assistance_vehicle .img_wrap::before {
        width: 312px;
        height: 312px;
        -webkit-filter: blur(100px);
        filter: blur(100px);
        left: 0;
        right: 0;
        margin: auto;
        top: 42%;
    }
    .assistance_wrap {
        flex-wrap: wrap;
    }
    .assistance_col {
        column-gap: 16px;
    }
    .assistance_col .img_wrap {
        width: 65px;
        height: 65px;
    }
    .assistance_col._left {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
    }
    .assistance_col._right {
        margin-left: 0;
    }
    .assistance_content .top_content {
        font-size: 20px;
        letter-spacing: -0.8px;
    }
    .assistance_content .middle_content {
        font-size: 28px;
        letter-spacing: -0.56px;
    }
    .assistance_content .bottom_content {
        letter-spacing: -0.28px;
    }
    .assistance_col .assistance_content {
        flex-basis: auto;
        width: calc(100% - (65px + 16px));
    }
    .assistance_wrap .seperator {
        width: 100%;
        height: 1px;
        background-color: var(--theme-white-20-color);
        margin-top: 22px;
        margin-bottom: 22px;
    }
    .assistance_vehicle {
        margin-top: 60px;
    }
}
/* assistance section end css */

/* enquiry section start css */
.enquiry_section {
    position: relative;
    overflow: hidden;
    padding: 2.60417vw 0 3.54167vw;
}
.enquiry_header {
    text-align: center;
}
.enquiry_header .secondary_heading {
    color: var(--theme-white-color);
    margin-bottom: 6px;
}
.enquiry_header p {
    color: var(--theme-white-color);
    font-size: 0.9375vw;
    margin-bottom: 0;
}
.enquiry_section .enquiry_form {
    width: 100%;
    position: relative;
    margin-top: 2.08333vw;
    padding-left: 2.86458vw;
    padding-right: 2.86458vw;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}
.enquiry_form .form_row {
    display: flex;
    row-gap: 20px;
    column-gap: 20px;
    margin-bottom: 15px;
}
.enquiry_form .form-group {
    width: calc((100% - 20px * 2) / 3);
}
.form-control {
    display: block;
    width: 100%;
    padding: 12px 26px;
    height: 50px;
    font-size: 16px;
    color: var(--theme-black-70-color);
    letter-spacing: -0.32px;
    background-color: var(--theme-white-color);
    border: 1px solid var(--theme-primary-color);
    box-shadow: 4px 4px 10px 0px rgba(98, 126, 154, 0.2);
    -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-weight: 300;
    outline: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
select.form-control {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7" fill="none"><path d="M1.5 0.834961L7 5.79064L12.5 0.834961" stroke="%231C1C1C" stroke-opacity="0.7" stroke-width="1.5" stroke-linecap="round"/></svg>');
    background-repeat: no-repeat;
    background-size: 12% 20%;
    background-position: 100% 55%;
    background-size: 14px 7px;
    background-position: 96% 55%;
}
.form-submit {
    background-color: var(--theme-primary-color);
    color: var(--theme-white-color);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.form-control::-webkit-input-placeholder {
    color: var(--theme-black-70-color);
}
.form-control:-moz-placeholder {
    color: var(--theme-black-70-color);
}
.form-control::-moz-placeholder {
    color: var(--theme-black-70-color);
}
.form-control:-ms-input-placeholder {
    color: var(--theme-black-70-color);
}
.form-control.error {
    border: 2px solid #ff2a2a;
}

@media only screen and (max-width: 1499px) {
    .enquiry_header p {
        font-size: 14px;
    }
    .form-control {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1199px) {
    select.form-control {
        /* background-size: 15% 15%; */
    }
}
@media only screen and (max-width: 767px) {
    .enquiry_section {
        padding: 24px 0;
    }
    .enquiry_header p {
        letter-spacing: -0.28px;
    }
    .enquiry_section .enquiry_form {
        margin-top: 24px;
    }
    .enquiry_form .form-group {
        width: 100%;
    }
    .enquiry_section .enquiry_form {
        column-gap: 0px;
        row-gap: 8px;
        padding-left: 0;
        padding-right: 0;
    }
    select.form-control {
        /* background-size: 15% 15%; */
    }
    .form-control {
        padding: 12px 16px;
    }
}
/* enquiry section end css */

/* our network start css */
.network_section {
    position: relative;
    overflow: hidden;
    padding: 3.54167vw 0;
    border-top: 1px solid var(--theme-white-10-color);
}
.network_header {
    text-align: center;
}
.network_header .secondary_heading {
    color: var(--theme-white-color);
    margin-bottom: 0.714em;
}
.network_wrap {
    position: relative;
    padding-left: 9.16667vw;
    padding-right: 9.16667vw;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.network_wrap .network_col {
    flex: 0 0 auto;
    width: auto;
}
.network_col._left {
    max-width: 26.82292vw;
    width: 40%;
    padding: 2.08333vw 2.60417vw 2.60417vw;
    background-color: var(--theme-primary-color);
}
.network_wrap .network_col._right {
    width: 60%;
    background-color: #e8eaed;
    overflow: hidden;
}
.network_col._left .heading {
    font-size: 1.875vw;
    font-weight: 600;
    letter-spacing: -1.44px;
    color: var(--theme-white-color);
    margin-bottom: 0.778em;
}
.network_col._left .form-group + .form-group {
    margin-top: 16px;
}
.network_form {
    margin-bottom: 28px;
}
.network_result_wrap .result_heading {
    font-size: 1.04167vw;
    font-weight: 600;
    color: var(--theme-white-color);
    margin-bottom: 22px;
}
.network_result {
    height: 17.60417vw;
    overflow-y: scroll;
}
.network_result::-webkit-scrollbar {
    width: 4px;
    background-color: var(--theme-secondary-color);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}
.network_result::-webkit-scrollbar-track {
    background-color: var(--theme-secondary-color);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.network_result::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: var(--theme-white-color);
    position: relative;
}
.network_result_row {
    padding-top: 22px;
    padding-bottom: 22px;
    border-top: 1px dashed var(--theme-white-50-color);
    border-bottom: 1px dashed var(--theme-white-50-color);
    margin-right: 0.72917vw;
}
.network_result_row .location_name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.32px;
    color: var(--theme-white-color);
    margin-bottom: 4px;
}
.network_result_row .location_address {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.32px;
    color: var(--theme-white-color);
    margin-bottom: 16px;
}
.network_result_row .result_btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.66667vw;
}
.network_result_row .result_btn .theme-btn-link {
    color: var(--theme-white-color);
    column-gap: 8px;
}
.network_result_row + .network_result_row {
    border-top: none;
}
.network_col._right iframe {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1499px) {
    .network_result_row {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .network_result_row .location_address {
        font-size: 13px;
        margin-bottom: 12px;
    }
}
@media only screen and (max-width: 1199px) {
    .network_col._left .heading {
        letter-spacing: -0.74px;
    }
    .network_result_wrap .result_heading {
        font-size: 14px;
    }
    .network_col._left {
        max-width: 100%;
        padding: 16px;
    }
}
@media only screen and (max-width: 767px) {
    .network_section {
        padding: 24px 0;
    }
    .network_header .secondary_heading {
        margin-bottom: 24px;
    }
    .network_wrap {
        padding-left: 0;
        padding-right: 0;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .network_col._left {
        width: 100%;
        max-width: 100%;
        padding: 24px 16px;
    }
    .network_col._left .heading {
        font-size: 20px;
        letter-spacing: -0.8px;
        margin-bottom: 20px;
    }
    .network_col._left .form-group + .form-group {
        margin-top: 12px;
    }
    .network_wrap .network_col._right {
        width: 100%;
        height: 245px;
    }
    .network_result_wrap .result_heading {
        font-size: 16px;
    }
    .network_result_row {
        margin-right: 24px;
    }
    .network_result_row .location_name {
        font-size: 14px;
        letter-spacing: -0.28px;
    }
    .network_result {
        height: 270px;
    }
    .network_result_row .location_address {
        font-size: 14px;
        letter-spacing: -0.28px;
        margin-bottom: 16px;
    }
    .network_result_row .result_btn .theme-btn-link {
        letter-spacing: -0.28px;
    }
}
/* our network end css */

/* customer testimonial section start css */
.testimonial_section {
    position: relative;
    overflow: hidden;
    padding: 3.54167vw 0;
    border-top: 1px solid var(--theme-white-10-color);
}
.testimonial_header {
    text-align: center;
}
.testimonial_header .secondary_heading {
    color: var(--theme-white-color);
    margin-bottom: 0.714em;
}
.testimonial_body {
    position: relative;

    margin-left: 5.20833vw;
    margin-right: 5.20833vw;
}
.testimonial_slider {
    overflow: hidden;
}
.testimonial_item {
    padding: 25px;
    background-color: var(--theme-white-color);
}
.testimonial_slider .testimonial_item {
    margin-right: 1.25vw;
}
.testimonial_img_wrap {
    position: relative;
}
.play_icon {
    position: absolute;
    right: 10px;
    bottom: -18%;
    width: 5.72917vw;
    height: 5.72917vw;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-primary-color);
    filter: drop-shadow(0px 25.038px 62.596px #dfefff);
    -webkit-filter: drop-shadow(0px 25.038px 62.596px #dfefff);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.testimonial_name {
    margin-top: 1.25em;
    font-size: 1.04167vw;
    font-weight: 600;
    margin-bottom: 8px;
}
.testimonial_position {
    font-size: 18px;
    font-weight: 300;
    color: var(--theme-black-70-color);
}
.testimonial_location {
    font-size: 18px;
    font-weight: 300;
    color: var(--theme-black-70-color);
}
.testimonial_btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}
.testimonial_btn li {
    list-style: none;
    cursor: pointer;
    position: absolute;
}
.testimonial_btn .prev_li {
    left: -5.20833vw;
}
.testimonial_btn .next_li {
    right: -5.20833vw;
}
.testimonial_btn li button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;
    padding: 0;
    width: 3.125vw;
    height: 3.125vw;
    overflow: hidden;
}
.testimonial_btn li button svg rect {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.testimonial_btn li button svg path {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.testimonial_btn li button:hover svg rect {
    fill: var(--theme-primary-color);
}
.testimonial_btn li button:hover svg path {
    fill: var(--theme-white-color);
}
.testimonial_section .swiper-pagination {
    display: none;
}

@media only screen and (max-width: 1499px) {
    .testimonial_position {
        font-size: 14px;
    }
    .testimonial_location {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1199px) {
    .play_icon svg {
        width: 25px;
        height: 25px;
    }
    .testimonial_name {
        font-size: 16px;
    }
}
@media only screen and (max-width: 767px) {
    .testimonial_section {
        padding: 24px 0 54px;
    }
    .testimonial_header .secondary_heading {
        margin-bottom: 24px;
    }
    .testimonial_body {
        margin-left: 0;
        margin-right: 0;
    }
    .testimonial_item {
        padding: 14px;
    }
    .testimonial_btn {
        display: none;
    }
    .play_icon {
        width: 60px;
        height: 60px;
    }
    .play_icon svg {
        width: 24px;
        height: 24px;
    }
    .testimonial_name {
        font-size: 14px;
        letter-spacing: -0.28px;
        margin-top: 8px;
    }
    .testimonial_location,
    .testimonial_position {
        letter-spacing: -0.28px;
    }
    .testimonial_section .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 0px;
        margin-top: 16px;
    }
    .testimonial_section .swiper-pagination span {
        background-color: var(--theme-white-70-color);
        width: 7px;
        height: 7px;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
        margin-left: 2px !important;
        margin-right: 2px !important;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
    }
    .testimonial_section .swiper-pagination .swiper-pagination-bullet-active {
        width: 20px;
        background-color: var(--theme-primary-color);
    }
    .testimonial_slider {
        overflow: unset;
    }
}
/* customer testimonial section end css */

/* faq section start css */
.faq_section {
    position: relative;
    overflow: hidden;
    padding: 3.54167vw 0;
}
.faq_section .faq_header {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.faq_section .faq_header .secondary_heading {
    line-height: 1;
    margin-bottom: 0.714em;
}
.faq_section .faq_accordion {
    position: relative;
}
.faq_accordion .accordion_item {
    border: 1px solid var(--theme-primary-color);
    background-color: var(--theme-white-color);
    padding: 24px 32px;
    position: relative;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: 4px 4px 10px 0px rgba(98, 126, 154, 0.2);
    -webkit-box-shadow: 4px 4px 10px 0px rgba(98, 126, 154, 0.2);
    -moz-box-shadow: 4px 4px 10px 0px rgba(98, 126, 154, 0.2);
    -o-box-shadow: 4px 4px 10px 0px rgba(98, 126, 154, 0.2);
    -ms-box-shadow: 4px 4px 10px 0px rgba(98, 126, 154, 0.2);
    transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
    -webkit-transition: background-color 0.3s ease-in-out,
        padding 0.3s ease-in-out;
    -moz-transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
    -ms-transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
    -o-transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
}
.faq_accordion .accordion_item + .accordion_item {
    margin-top: 16px;
}
.faq_accordion .accordion_item .title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    color: var(--theme-secondary-color);
    cursor: pointer;
    display: block;
    position: relative;
    transition: color 0.3s ease-in-out, margin 0.3s ease-in-out;
    -webkit-transition: color 0.3s ease-in-out, margin 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out, margin 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out, margin 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out, margin 0.3s ease-in-out;
}
.faq_accordion .accordion_item .title .faq_icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.faq_accordion .accordion_item .accordion_content,
.faq_accordion .accordion_item:not(:first-child) .accordion_content {
    display: none;
}
.faq_accordion .accordion_item .accordion_content,
.faq_accordion .accordion_item .accordion_content p {
    max-width: 51.5625vw;
    color: var(--theme-secondary-color);
    margin-bottom: 0;
    font-size: 18px;
    transition: color 0.3s ease-in-out;
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
}
.faq_accordion .accordion_item.active {
    background-color: var(--theme-primary-color);
    padding-top: 30px;
    padding-bottom: 30px;
}
.faq_accordion .accordion_item.active .title {
    color: var(--theme-white-color);
    margin-bottom: 20px;
}
.faq_accordion .accordion_item.active .title .faq_icon {
    transform: translateY(-50%) rotate(0deg);
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
}
.faq_accordion .accordion_item.active .accordion_content {
    display: block;
}
.faq_accordion .accordion_item.active .accordion_content,
.faq_accordion .accordion_item.active .accordion_content p {
    color: var(--theme-white-color);
}

@media only screen and (max-width: 1499px) {
    .faq_accordion .accordion_item .title {
        font-size: 18px;
    }
    .faq_accordion .accordion_item .accordion_content,
    .faq_accordion .accordion_item .accordion_content p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 1199px) {
    .faq_accordion .accordion_item {
        padding: 16px 20px;
    }
    .faq_accordion .accordion_item.active {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .faq_accordion .accordion_item .accordion_content {
        max-width: 90%;
    }
    .faq_accordion .accordion_item .accordion_content p {
        max-width: 100%;
    }
    .faq_accordion .accordion_item .title {
        font-size: 18px;
    }
    .faq_accordion .accordion_item.active .title {
        margin-bottom: 16px;
    }
    .faq_accordion .accordion_item .title .faq_icon {
        width: 28px;
        height: 28px;
    }
    .faq_accordion .accordion_item .title .faq_icon svg {
        width: 100%;
        height: 100%;
    }
}
@media only screen and (max-width: 767px) {
    .faq_section {
        padding: 24px 0 120px;
    }
    .faq_section .faq_header {
        max-width: 250px;
    }
    .faq_section .faq_header .secondary_heading {
        margin-bottom: 24px;
    }
    .faq_accordion .accordion_item {
        padding: 8px 16px;
    }
    .faq_accordion .accordion_item.active {
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .faq_accordion .accordion_item .title {
        font-size: 16px;
        padding-right: 28px;
    }
    .faq_accordion .accordion_item .title .faq_icon {
        width: 28px;
        height: 28px;
    }
    .faq_accordion .accordion_item .title .faq_icon svg {
        width: 100%;
        height: 100%;
    }
    .faq_accordion .accordion_item.active .title {
        margin-bottom: 12px;
    }
    .faq_accordion .accordion_item .accordion_content {
        max-width: 100%;
    }
    .faq_accordion .accordion_item .accordion_content,
    .faq_accordion .accordion_item .accordion_content p {
        font-size: 14px;
        letter-spacing: -0.28px;
    }
    .faq_accordion .accordion_item + .accordion_item {
        margin-top: 8px;
    }
}
/* faq section end css */

.slide_wrap {
    position: relative;
}
.banner_section .slick-dots {
    display: flex;
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: auto;
    bottom: 24px;
    right: 24px;
    left: auto;
    width: auto !important;
}
.testimonial_slider .slick-dots {
    display: flex !important;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
}
.testimonial_slider .slick-dots li,
.banner_section .slick-dots li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7px;
    height: 7px;
    margin-left: 2px !important;
    margin-right: 2px !important;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
.testimonial_slider .slick-dots li button,
.banner_section .slick-dots li button {
    background-color: var(--theme-white-70-color);
    width: 7px;
    height: 7px;
    font-size: 0;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    padding: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    box-shadow: none;
    border: none;
}
.testimonial_slider .slick-dots li.slick-active,
.banner_section .slick-dots li.slick-active {
    width: 20px;
}
.testimonial_slider .slick-dots li.slick-active button,
.banner_section .slick-dots li.slick-active button {
    width: 20px;
    background-color: var(--theme-primary-color);
}
.quality_section .swiper-pagination-quality {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
    margin-top: 27px;
}
.quality_section .swiper-pagination-quality span {
    background-color: var(--theme-white-70-color);
    width: 7px;
    height: 7px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    margin-left: 2px !important;
    margin-right: 2px !important;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.quality_section .swiper-pagination-quality .swiper-pagination-bullet-active {
    width: 20px;
    background-color: var(--theme-primary-color);
}
.slick-dots li::before {
    display: none;
}
.slick-dots li button:before {
    display: none;
}
@media only screen and (max-width: 767px) {
    .banner_section .slick-dots {
        display: flex !important;
    }
    .testimonial_slider .testimonial_item {
        margin-right: 8px;
    }
    .quality_section .slick-dots {
        display: flex !important;
        margin: 0;
        padding: 0;
        list-style: none;
        align-items: center;
        justify-content: center;
    }
    .quality_section .slick-dots li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7px;
        height: 7px;
        margin-left: 2px !important;
        margin-right: 2px !important;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
    }
    .quality_section .slick-dots li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7px;
        height: 7px;
        margin-left: 2px !important;
        margin-right: 2px !important;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
    }
    .quality_section .slick-dots li button {
        background-color: var(--theme-white-70-color);
        width: 7px;
        height: 7px;
        font-size: 0;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
        padding: 0;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        box-shadow: none;
        border: none;
    }
    .quality_section .slick-dots li.slick-active {
        width: 20px;
    }
    .quality_section .slick-dots li.slick-active button {
        width: 20px;
        background-color: var(--theme-primary-color);
    }
}
