/* Normalize css */

*,
::after,
::before {
    box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
h1 {
    font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
    h1 {
        font-size: 2.5rem;
    }
}
h2 {
    font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
    h2 {
        font-size: 2rem;
    }
}
h3 {
    font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
    h3 {
        font-size: 1.75rem;
    }
}
h4 {
    font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
    h4 {
        font-size: 1.5rem;
    }
}
h5 {
    font-size: 1.25rem;
}
h6 {
    font-size: 1rem;
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}
address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}
ol,
ul {
    padding-left: 2rem;
}
dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}
dt {
    font-weight: 700;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}
blockquote {
    margin: 0 0 1rem;
}
b,
strong {
    font-weight: bolder;
}
small {
    font-size: 0.875em;
}
mark {
    padding: 0.1875em;
    color: #212529;
    background-color: #fff3cd;
}
sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
a {
    color: rgba(13, 110, 253, 1);
    text-decoration: underline;
}
a:hover {
    color: rgb(139, 185, 254);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}
code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    font-size: 1em;
}
pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
}
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}
code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word;
}
a > code {
    color: inherit;
}
kbd {
    padding: 0.1875rem 0.375rem;
    font-size: 0.875em;
    color: #212529;
    background-color: #212529;
    border-radius: 0.25rem;
}
kbd kbd {
    padding: 0;
    font-size: 1em;
}
figure {
    margin: 0 0 1rem;
}
img,
svg {
    vertical-align: middle;
}
table {
    caption-side: bottom;
    border-collapse: collapse;
}
caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: rgba(33, 37, 41, 0.75);
    text-align: left;
}
th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
label {
    display: inline-block;
}
button {
    border-radius: 0;
}
button:focus:not(:focus-visible) {
    outline: 0;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
select {
    text-transform: none;
}
[role="button"] {
    cursor: pointer;
}
select {
    word-wrap: normal;
}
select:disabled {
    opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
        [type="week"]
    ):not([type="time"])::-webkit-calendar-picker-indicator {
    display: none !important;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
textarea {
    resize: vertical;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
}
@media (min-width: 1200px) {
    legend {
        font-size: 1.5rem;
    }
}
legend + * {
    clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0;
}
::-webkit-inner-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
    padding: 0;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
::file-selector-button {
    font: inherit;
    -webkit-appearance: button;
}
output {
    display: inline-block;
}
iframe {
    border: 0;
}
summary {
    display: list-item;
    cursor: pointer;
}
progress {
    vertical-align: baseline;
}
[hidden] {
    display: none !important;
}

/* grid css */
.row {
    --theme-gutter-x: 0;
    --theme-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--theme-gutter-y));
    margin-right: calc(-0.5 * var(--theme-gutter-x));
    margin-left: calc(-0.5 * var(--theme-gutter-x));
}
.row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--theme-gutter-x) * 0.5);
    padding-left: calc(var(--theme-gutter-x) * 0.5);
    margin-top: var(--theme-gutter-y);
}
.col {
    flex: 1 0 0%;
}
.row-cols-auto > * {
    flex: 0 0 auto;
    width: auto;
}
.row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%;
}
.row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%;
}
.row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
}
.row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%;
}
.row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%;
}
.row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
}
.col-auto {
    flex: 0 0 auto;
    width: auto;
}
.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
}
.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}
.col-3 {
    flex: 0 0 auto;
    width: 25%;
}
.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}
.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}
.col-6 {
    flex: 0 0 auto;
    width: 50%;
}
.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
}
.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
}
.col-9 {
    flex: 0 0 auto;
    width: 75%;
}
.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}
.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
}
.col-12 {
    flex: 0 0 auto;
    width: 100%;
}
.offset-1 {
    margin-left: 8.33333333%;
}
.offset-2 {
    margin-left: 16.66666667%;
}
.offset-3 {
    margin-left: 25%;
}
.offset-4 {
    margin-left: 33.33333333%;
}
.offset-5 {
    margin-left: 41.66666667%;
}
.offset-6 {
    margin-left: 50%;
}
.offset-7 {
    margin-left: 58.33333333%;
}
.offset-8 {
    margin-left: 66.66666667%;
}
.offset-9 {
    margin-left: 75%;
}
.offset-10 {
    margin-left: 83.33333333%;
}
.offset-11 {
    margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
    --theme-gutter-x: 0;
}
.g-0,
.gy-0 {
    --theme-gutter-y: 0;
}
.g-1,
.gx-1 {
    --theme-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
    --theme-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
    --theme-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
    --theme-gutter-y: 0.5rem;
}
.g-3,
.gx-3 {
    --theme-gutter-x: 1rem;
}
.g-3,
.gy-3 {
    --theme-gutter-y: 1rem;
}
.g-4,
.gx-4 {
    --theme-gutter-x: 1.5rem;
}
.g-4,
.gy-4 {
    --theme-gutter-y: 1.5rem;
}
.g-5,
.gx-5 {
    --theme-gutter-x: 3rem;
}
.g-5,
.gy-5 {
    --theme-gutter-y: 3rem;
}
@media (min-width: 768px) {
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}
:root {
    --fallback-font-family: system-ui, -apple-system, "Segoe UI", Roboto,
        "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    --font-family: "Archia";
    --theme-white-color: #ffffff;
    --theme-white-05-color: rgba(255, 255, 255, 0.05);
    --theme-white-10-color: rgba(255, 255, 255, 0.1);
    --theme-white-20-color: rgba(255, 255, 255, 0.2);
    --theme-white-30-color: rgba(255, 255, 255, 0.3);
    --theme-white-40-color: rgba(255, 255, 255, 0.4);
    --theme-white-50-color: rgba(255, 255, 255, 0.5);
    --theme-white-60-color: rgba(255, 255, 255, 0.6);
    --theme-white-70-color: rgba(255, 255, 255, 0.7);
    --theme-white-80-color: rgba(255, 255, 255, 0.8);
    --theme-white-90-color: rgba(255, 255, 255, 0.9);
    --theme-white-100-color: rgba(255, 255, 255, 1);
    --theme-black-color: #000000;
    --theme-black-10-color: rgba(0, 0, 0, 0.1);
    --theme-black-20-color: rgba(0, 0, 0, 0.2);
    --theme-black-30-color: rgba(0, 0, 0, 0.3);
    --theme-black-40-color: rgba(0, 0, 0, 0.4);
    --theme-black-50-color: rgba(0, 0, 0, 0.5);
    --theme-black-60-color: rgba(0, 0, 0, 0.6);
    --theme-black-70-color: rgba(0, 0, 0, 0.7);
    --theme-black-80-color: rgba(0, 0, 0, 0.8);
    --theme-black-90-color: rgba(0, 0, 0, 0.9);
    --theme-black-100-color: rgba(0, 0, 0, 1);
    --theme-primary-color: #356998;
    --theme-secondary-color: #1c1c1c;
    --theme-light-gray-color: #f5f5f5;
}
*,
::after,
::before {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}
body {
    font-family: var(--fallback-font-family);
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--theme-secondary-color);
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.main {
    font-family: var(--fallback-font-family);
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: var(--theme-secondary-color);
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.main_wrapper {
    /* width: 100%; */
}
@media only screen and (max-width: 767px) {
    .main {
        /* width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 16px; */
        /* overflow-x: hidden; */
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    margin-bottom: 0;
}

a,
button {
    outline: none;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.bg_black {
    background-color: var(--theme-black-color);
}
.bg_white {
    background-color: var(--theme-white-color);
}
.bg_primary {
    background-color: var(--theme-primary-color);
}
.bg_secondary {
    background-color: var(--theme-secondary-color);
}
.text_black {
    color: var(--theme-black-color);
}
.text_white {
    color: var(--theme-white-color);
}
.text_primary {
    color: var(--theme-primary-color);
}
.text_secondary {
    color: var(--theme-1C1C1C-color);
}

.fw-100 {
    font-weight: 100;
}
.fw-200 {
    font-weight: 200;
}
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-800 {
    font-weight: 800;
}
.fw-900 {
    font-weight: 900;
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.d-none {
    display: none;
}
.d-lg {
    display: block;
}
.d-md {
    display: none;
}
.d-xs {
    display: none;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}

.theme-btn {
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 16px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    overflow: hidden;
    position: relative;
    letter-spacing: -0.32px;
    padding: 0.688em 2.5em;
    column-gap: 6px;
    color: var(--theme-black-color);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.theme-btn-primary {
    background-color: var(--theme-primary-color);
    color: var(--theme-white-color);
}
.theme-btn-primary:active,
.theme-btn-primary:visited {
    color: var(--theme-white-color);
}
.theme-btn-primary:hover {
    background-color: var(--theme-black-color);
    color: var(--theme-white-color);
}
.theme-btn-secondary {
    background-color: var(--theme-white-color);
    color: var(--theme-black-color);
}
.theme-btn-secondary:hover {
    background-color: var(--theme-black-color);
    color: var(--theme-white-color);
}
.theme-btn:hover {
    border: 1px solid var(--theme-white-color);
}
.theme-btn .btn_icon,
.theme-btn-link .btn_icon {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.theme-btn-link {
    background-color: transparent;
    font-size: 16px;
    color: var(--theme-black-color);
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    letter-spacing: -0.32px;
}

.custom-container {
    width: 100%;
    padding-left: 3.85417vw;
    padding-right: 3.85417vw;
}

/* navigation start css */
.logo_area a {
    display: block;
}
.main_menu {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 85px;
    width: 100%;
    padding: 12px 3.85417vw;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
}
.book_now_wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 12px;
}

/* navigation end css */

@media only screen and (max-width: 1499px) {
    .theme-btn {
        font-size: 14px;
    }
    .theme-btn-link {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1399px) {
}
@media only screen and (max-width: 1199px) {
    .d-md {
        display: block !important;
    }
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
    .d-lg {
        display: none !important;
    }
    .d-xs {
        display: block !important;
    }
    .logo_area a {
        width: 90px;
    }
    .main_menu {
        padding: 12px 16px;
        min-height: auto;
    }
    .main_menu .theme-btn {
        letter-spacing: -0.28px;
        padding: 0.5em 1.143em;
    }
    .custom-container {
        padding-left: 16px;
        padding-right: 16px;
    }
}
