.footer {
    padding: 16px 0;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    overflow: hidden;
    z-index: 5;
}
.footer_list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: space-between;
}
.footer_list li a .list_icon {
    /* width: 22px; */
    /* height: 22px; */
    display: inline-block;
}
.footer_list li a .list_icon svg {
    width: 100%;
    height: 100%;
}
.footer_list li a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: var(--theme-white-color);
    font-size: 0.9375vw;
    letter-spacing: -0.3px;
    line-height: 1;
}
.fixed_mobile_menu {
    display: none;
}

@media only screen and (max-width: 1499px) {
    .footer_list li a {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .footer {
        display: none;
    }
    /* fixed mobile menu start css */
    .fixed_mobile_menu {
        position: fixed;
        bottom: 0;
        z-index: 5;
        background-color: var(--theme-primary-color);
        width: 100%;
        height: 63px;
        display: block;
    }
    .fixed_mobile_menu .menu {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
    }
    .fixed_mobile_menu .menu li {
        width: calc(100% / 3);
        position: relative;
    }
    .fixed_mobile_menu .menu li a {
        display: block;
    }
    .fixed_mobile_menu .menu li span {
        display: block;
        text-align: center;
    }
    .fixed_mobile_menu .menu li span.text {
        font-size: 12px;
        color: var(--theme-white-70-color);
    }
    .fixed_mobile_menu .menu li span.text.center_text {
        /* color: var(--theme-white-color); */
    }
    .fixed_mobile_menu .menu li .list_icon svg path {
        stroke: var(--theme-white-70-color);
    }
    .fixed_mobile_menu .menu li:nth-child(2) .list_icon,
    .fixed_mobile_menu .menu li .list_icon.center {
        width: 68px;
        height: 68px;
        position: relative;
        left: auto;
        right: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: -50px;
        margin-bottom: 10px;
        border: 1px dashed var(--theme-white-color);
        border-radius: 68px;
        -webkit-border-radius: 68px;
        -moz-border-radius: 68px;
        -ms-border-radius: 68px;
        -o-border-radius: 68px;
        background-color: var(--theme-primary-color);
        box-shadow: 2px 2px 12px 0px rgba(95, 159, 199, 0.7);
        -webkit-box-shadow: 2px 2px 12px 0px rgba(95, 159, 199, 0.7);
        -moz-box-shadow: 2px 2px 12px 0px rgba(95, 159, 199, 0.7);
        -o-box-shadow: 2px 2px 12px 0px rgba(95, 159, 199, 0.7);
        -ms-box-shadow: 2px 2px 12px 0px rgba(95, 159, 199, 0.7);
        overflow: hidden;
        padding: 8px;
    }
    .fixed_mobile_menu .menu li:nth-child(2)::after,
    .fixed_mobile_menu .menu li:nth-child(2)::before,
    .fixed_mobile_menu .menu .center_li::after,
    .fixed_mobile_menu .menu .center_li::before {
        content: "";
        position: absolute;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="2" height="48" viewBox="0 0 2 48" fill="none"><path d="M1 47L1 1" stroke="url(%23paint0_linear_50_482)" stroke-linecap="round"/><defs><linearGradient id="paint0_linear_50_482" x1="3" y1="47" x2="3" y2="1" gradientUnits="userSpaceOnUse"><stop stop-color="white" stop-opacity="0"/><stop offset="0.535" stop-color="white"/><stop offset="1" stop-color="white" stop-opacity="0"/></linearGradient></defs></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        width: 2px;
        height: 48px;
        top: 0;
    }
    .fixed_mobile_menu .menu li:nth-child(2)::before,
    .fixed_mobile_menu .menu .center_li::before {
        left: 0;
    }
    .fixed_mobile_menu .menu li:nth-child(2)::after,
    .fixed_mobile_menu .menu .center_li::after {
        left: auto;
        right: 0;
    }

    /* fixed mobile menu end css */
}
