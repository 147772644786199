.App {
  text-align: center;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.custom-dropdown-header {
  padding: 15px 24px;
  background-color: white;
  font-weight: 700;
  border: 1px solid black;
  color: #757575;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: start;
  background-color: white;
  border: 1px solid black;
  color: #757575;

  margin-top: 5px;
  list-style: none;
  padding: 0;
  z-index: 10;
}

.custom-dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.custom-dropdown-item:hover {
  background-color: #f0f0f0;
}

.hover:hover
{
  background:#9CCDFB;
}

.Veh{
padding: 30px 30px 0 30px;
}

.Veh:hover
{
  background-color: #99cefb;
  cursor: pointer;
}

.Veh.active
{
  background-color: #99cefb;
}

@media (min-width: 300px) and (max-width: 767px) {  

  .mainveh
  {
    display: block !important;
  }
  .Veh{
    padding: 10px 30px !important;
    margin: 5px !important;
    border: 2px solid #3F7EB6;
    }
 }




input, textarea {
  font-family: 'archiabold';
}



.h4-custom {
  font-family: 'archiabold';
}

.blog_content a {
  color: #0257A1 !important;
}

.featured-section {
  background-color: rgb(28, 28, 28);
  background: linear-gradient(to top,
      rgb(28, 28, 28) 0%,
      rgb(28, 28, 28) 20%,
      white 20%,
      white 100%);
}





.carousel .slide img {
  width: auto !important;
  text-align: left !important;
}

.carousel .carousel-status {
  display: none !important;
}

.carousel .control-dots .dot.selected {
  background-color: #99CEFB !important;
  height: 20px !important;
  width: 20px !important;

}

.carousel .control-dots .dot {
  background-color: white !important;
  height: 20px !important;
  width: 20px !important;

}

.carousel .slide {
  text-align: left !important;
}


.carousel .control-dots {
  margin: 10px 0 !important;
}



input:focus, textarea:focus {
  border: 1px solid #F4F4F4 0% 0% no-repeat padding-box
}

@font-face {
  font-family: "Archia";
  src: local("Archia"),
    url("./fonts/archia-semibold-webfont.woff") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "archiathin";
  src: local("Archia"),
    url("./fonts/archia-thin-webfont.woff") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "archialight";
  src: local("Archia"),
    url("./fonts/archia-light-webfont.woff") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "archiaregular";
  src: local("Archia"),
    url("./fonts/archia-regular-webfont.woff") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "archiamedium";
  src: local("Archia"),
    url("./fonts/archia-medium-webfont.woff") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "archiasemibold";
  src: local("Archia"),
    url("./fonts/archia-semibold-webfont.woff") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "archiabold";
  src: local("Archia"),
    url("./fonts/archia-semibold-webfont.woff") format("truetype");
  font-display: swap;
}





:root {
  --primary: #56cfed;
  --primary-dark: #9ccdfb;
  --grey-black: #2e2e2e;
  --light-blue: #d6ebff;
  --dark: #000;
  --dark-blue: #56cfed;
  --text-grey: #949494;
  --font-t: "archiathin";
  --font-l: "archialight";
  --font-r: "archiaregular";
  --font-m: "archiamedium";
  --font-sm: "archiasemibold";
  --font-b: "archiabold";
  --font-awesome: "Font Awesome 5 Free";
  --box-shadow: "inset 0 0 6px rgba(0,0,0,0.3)";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-gallery {
  width: 100%;
  /* height: 92vh; */
}

.image-gallery-slide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.image-gallery-image {
  object-fit: cover !important;
  max-height: 100%;
}

.image-gallery-slide {
  object-fit: cover !important;
  max-height: 771px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 126px);
}

/* .fullscreen .image-gallery-slide img {
  max-height: 100vh;
} */

select::-ms-expand {
  padding: 20px;
}

select {
  display: inline-block;
  box-sizing: border-box;

  padding: 0.5em 2em 0.5em 0.5em;
  border: 1px solid #eee;
  font: inherit;
  line-height: inherit;
  appearance: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: right 15px top 1em, right 10px top 1em;
  background-size: 5px 5px, 5px 5px;
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

a {
  text-decoration: none;
  color: inherit;
}

a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

#locations::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}

#locations::-webkit-scrollbar-thumb {
  background-color: #9ccdfb;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  /* creates padding around scroll thumb */
}

iframe {
  pointer-events: none;
}

@media screen and (max-width: 1024px) {
  .form-row {
    flex-direction: column;
  }

  .input_box {
    width: 20rem;
  }

  .btn_primary,
  .btn_ul {
    align-self: flex-start;
  }

  .radio_container {
    flex-direction: column;
    gap: 1rem;
  }



  .form_container {
    padding: 1rem;
  }

  .contact_info {
    margin-bottom: 1rem;
  }

  .section_heading,
  .booking_id {
    font-size: 1rem;
  }

  .container {
    gap: 1rem;
    padding: 1rem;
  }

  .two_col {
    display: grid;
    grid-template-columns: 1fr;
  }

  .input_box_m {
    width: 20rem;
  }

  .form_heading {
    font-size: 1rem;
  }

  .form_subtext {
    font-size: 14px;
  }
}

.booked_vehicle_name,
.booked_vehicle_qty {
  margin-left: 0;
}


.reactMarkDown {
  font-family: 'Archia';
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}



.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

@media screen and (max-width: 768px) {
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vw *(4/3));
  }

  .featured-section {
    background: white;
  }
}