*, *::after, *::before {
  box-sizing: border-box;
}

img { 
  object-fit: cover;
  max-width: 100%;
}

p {
  font-family: 'archiathin'; 
}


@import "~react-image-gallery/styles/css/image-gallery.css";
body {
  margin: 0;
  font-family: 'archiabold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 
.image {
  background-size: cover;
  background-repeat: no-repeat;
}
