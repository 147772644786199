@font-face {
    font-family: "Archia";
    src: url("./fonts/Archia-Thin.eot");
    src: url("./fonts/Archia-Thin.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Archia-Thin.woff2") format("woff2"),
        url("./fonts/Archia-Thin.woff") format("woff"),
        url("./fonts/Archia-Thin.ttf") format("truetype"),
        url("./fonts/Archia-Thin.svg#Archia-Thin") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Archia";
    src: url("./fonts/Archia-Light.eot");
    src: url("./fonts/Archia-Light.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Archia-Light.woff2") format("woff2"),
        url("./fonts/Archia-Light.woff") format("woff"),
        url("./fonts/Archia-Light.ttf") format("truetype"),
        url("./fonts/Archia-Light.svg#Archia-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Archia";
    src: url("./fonts/Archia-Regular.eot");
    src: url("./fonts/Archia-Regular.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Archia-Regular.woff2") format("woff2"),
        url("./fonts/Archia-Regular.woff") format("woff"),
        url("./fonts/Archia-Regular.ttf") format("truetype"),
        url("./fonts/Archia-Regular.svg#Archia-Regular") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Archia";
    src: url("./fonts/Archia-Medium.eot");
    src: url("./fonts/Archia-Medium.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Archia-Medium.woff2") format("woff2"),
        url("./fonts/Archia-Medium.woff") format("woff"),
        url("./fonts/Archia-Medium.ttf") format("truetype"),
        url("./fonts/Archia-Medium.svg#Archia-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Archia";
    src: url("./fonts/Archia-SemiBold.eot");
    src: url("./fonts/Archia-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Archia-SemiBold.woff2") format("woff2"),
        url("./fonts/Archia-SemiBold.woff") format("woff"),
        url("./fonts/Archia-SemiBold.ttf") format("truetype"),
        url("./fonts/Archia-SemiBold.svg#Archia-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Archia";
    src: url("./fonts/Archia-Bold.eot");
    src: url("./fonts/Archia-Bold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Archia-Bold.woff2") format("woff2"),
        url("./fonts/Archia-Bold.woff") format("woff"),
        url("./fonts/Archia-Bold.ttf") format("truetype"),
        url("./fonts/Archia-Bold.svg#Archia-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
